import React, { useState, useEffect } from "react"
import axios from "axios"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"
import ReactPaginate from "react-paginate"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./Facturas.css"
import * as XLSX from "xlsx"

const baseURL = process.env.REACT_APP_API_URL

// Función para formatear números como moneda
const formatCurrency = value => {
  return new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
  }).format(value)
}

const Facturas = props => {
  document.title = "Comprobantes | LS-DA - Softlogy"

  const navigate = useNavigate()

  const [facturas, setFacturas] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [totalDocs, setTotalDocs] = useState(0)
  const [loading, setLoading] = useState(false)
  const [documentos, setDocumentos] = useState([])
  const [empresas, setEmpresas] = useState([])

  const [nitemisor, setNitemisor] = useState("")
  const [fechaInicio, setFechaInicio] = useState(null)
  const [fechaFin, setFechaFin] = useState(null)
  const [tipoDocumento, setTipoDocumento] = useState("TODOS LOS DOCUMENTOS")
  const [currentPage, setCurrentPage] = useState(0)

  const [modal, setModal] = useState(false)
  const [modalContent] = useState("")
  const [detalleModal, setDetalleModal] = useState(false)
  const [detalleContent, setDetalleContent] = useState(null)
  const [tipoReceptor, setTipoReceptor] = useState("Todos")
  const [sucursales, setSucursales] = useState([])
  const [selectedSucursal, setSelectedSucursal] = useState("")
  const [selectedSucursalId, setSelectedSucursalId] = useState("")

  const [prefijos, setPrefijos] = useState([])
  const [selectedPrefijo, setSelectedPrefijo] = useState("")

  const toggleModal = () => setModal(!modal)
  const toggleDetalleModal = () => setDetalleModal(!detalleModal)

  const [loadingPDFs, setLoadingPDFs] = useState({})
  const [downloadProgresses, setDownloadProgresses] = useState({})

  // Función para consumir la API de reenvío de correo
  const handleResendEmail = async cufe => {
    const mail = prompt(
      "Ingrese el correo al que se debe reenviar el comprobante:",
    )
    if (!mail) {
      alert("Debe ingresar un correo válido.")
      return
    }

    const apiUrl = `${baseURL}invoice/mail`

    try {
      const response = await axios.post(apiUrl, {
        cufe,
        mail,
      })
      console.log("Respuesta de reenvío:", response.data)
      alert("Correo reenviado con éxito.")
    } catch (error) {
      console.error("Error al reenviar el correo:", error)
      alert("Ocurrió un error al reenviar el correo.")
    }
  }

  const handleDianClick = cufe => {
    const url = `https://catalogo-vpfe.dian.gov.co/User/SearchDocument?DocumentKey=${cufe}`
    window.open(url, "_blank")
  }

  const handleDetalleClick = async (
    prefijo,
    folio,
    numeroEmisor,
    numeroReceptor,
  ) => {
    try {
      const response = await axios.get(`${baseURL}invoice/consulta`, {
        params: {
          prefijo,
          folio,
          numeroemisor: numeroEmisor,
          numeroReceptor,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser")).access}`,
        },
      })
      setDetalleContent(response.data.comprobante)
      toggleDetalleModal()
    } catch (error) {
      console.error("Error fetching detalle data:", error)
    }
  }

  const handlePDFClick = async cufe => {
    setLoadingPDFs(prevState => ({ ...prevState, [cufe]: true }))
    setDownloadProgresses(prevState => ({ ...prevState, [cufe]: 0 }))

    try {
      const storedUser = localStorage.getItem("authUser")
      const currentUser = storedUser ? JSON.parse(storedUser) : null

      const apiUrl = `${baseURL}invoice/pdf/${cufe}`

      const response = await axios.get(apiUrl, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${currentUser.access}`,
        },
        onDownloadProgress: progressEvent => {
          const total = progressEvent.total
          const current = progressEvent.loaded

          if (total !== null) {
            const percentage = Math.round((current * 100) / total)
            setDownloadProgresses(prevState => ({
              ...prevState,
              [cufe]: percentage,
            }))
          } else {
            setDownloadProgresses(prevState => ({ ...prevState, [cufe]: 100 }))
          }
        },
      })

      const pdfBlob = new Blob([response.data], { type: "application/pdf" })
      const pdfUrl = URL.createObjectURL(pdfBlob)

      const link = document.createElement("a")
      link.href = pdfUrl
      link.download = `${cufe}.pdf`
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(pdfUrl)
    } catch (error) {
      console.error("Error al obtener el PDF:", error)
      alert(
        "Ocurrió un error al obtener el PDF. Por favor, inténtalo de nuevo.",
      )
    } finally {
      setLoadingPDFs(prevState => ({ ...prevState, [cufe]: false }))
      setDownloadProgresses(prevState => ({ ...prevState, [cufe]: 0 }))
    }
  }

  const fetchFacturas = async (page = 1) => {
    setLoading(true)
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    console.log("Fetching facturas for page: ", page)
    console.log("API  call parameters: ", {
      nitemisor: nitemisor,
      fechaInicio: fechaInicio ? fechaInicio.toISOString().split("T")[0] : "",
      fechaFin: fechaFin ? fechaFin.toISOString().split("T")[0] : "",
      tipoDocumento:
        tipoDocumento !== "TODOS LOS DOCUMENTOS" ? tipoDocumento : undefined,
      sucursal: selectedSucursal,
      prefijo: selectedPrefijo,
      page: page,
    })

    try {
      const response = await axios.get(`${baseURL}invoice/recupera`, {
        params: {
          nitemisor: nitemisor,
          fechaInicio: fechaInicio
            ? fechaInicio.toISOString().split("T")[0]
            : "",
          fechaFin: fechaFin ? fechaFin.toISOString().split("T")[0] : "",
          tipoDocumento:
            tipoDocumento !== "TODOS LOS DOCUMENTOS"
              ? tipoDocumento
              : undefined,
          tipoReceptor: tipoReceptor,
          sucursal: selectedSucursal,
          prefijo: selectedPrefijo,
          page: page,
        },
        headers: {
          Authorization: `Bearer ${currentUser.access}`,
        },
      })
      setFacturas(response.data.facturas)
      setTotalPages(response.data.totalPages)
      setTotalDocs(response.data.totalDocs)
      setCurrentPage(page - 1) // Ajustado a cero-indexado
    } catch (error) {
      console.error("Error fetching facturas data:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchTipoDocumento = async () => {
    try {
      const response = await axios.get("/listas/TipoDocumento-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const types = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          types.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setDocumentos(types)
    } catch (error) {
      console.error("Error fetching document types:", error)
    }
  }

  const fetchEmpresas = async () => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    if (!currentUser) {
      console.log("User data not fully available yet, skipping fetch.")
      return
    }

    console.log("Current user:", currentUser)

    if (currentUser.role === "SuperAdmin") {
      // Obtener empresas desde la API
      if (!currentUser.IdEmpresa || !currentUser.access) {
        console.log("User data not fully available yet, skipping fetch.")
        return
      }
      console.log("IDEMPRESA: ", currentUser.IdEmpresa)
      try {
        const response = await axios.get(
          `${baseURL}empresas/obtener?id=${currentUser.IdEmpresa}`,
          {
            headers: {
              Authorization: `Bearer ${currentUser.access}`,
            },
          },
        )
        console.log("Empresas data fetched:", response.data)
        setEmpresas(response.data)
      } catch (error) {
        console.error("Error fetching empresas data:", error)
      }
    } else {
      // Establecer empresas desde currentUser.empresas
      if (!currentUser.empresas) {
        console.log("User data not fully available yet, skipping fetch.")
        return
      }
      try {
        console.log("Empresas data fetched:", currentUser.empresas)
        setEmpresas(currentUser.empresas)
      } catch (error) {
        console.error("Error setting empresas data:", error)
      }
    }
  }

  const fetchSucursales = async nit => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    if (!currentUser || !currentUser.access) {
      console.log("User data not fully available yet, skipping fetch.")
      return
    }

    setLoading(true)
    console.log("Fetching sucursales data...")
    try {
      const response = await axios.get(
        `${baseURL}empresas/sucursales?id=${nit}`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.access}`,
          },
        },
      )
      console.log("Sucursales data fetched:", response.data)
      setSucursales(response.data.sucursales || [])
    } catch (error) {
      console.error("Error fetching sucursales data:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchPrefijos = async sucursalId => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    if (!currentUser || !currentUser.access) {
      console.log("User data not fully available yet, skipping fetch.")
      return
    }

    setLoading(true)
    console.log("Fetching prefijos data...")
    try {
      const response = await axios.get(`${baseURL}resoluciones/prefijos`, {
        params: {
          sucursal: sucursalId,
        },
        headers: {
          Authorization: `Bearer ${currentUser.access}`,
        },
      })
      console.log("Prefijos data fetched:", response.data)
      setPrefijos(response.data.prefijos || [])
    } catch (error) {
      console.error("Error fetching prefijos data:", error)
      setPrefijos([]) // En caso de error, vaciamos los prefijos
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (currentUser) {
      const userGreeting = `Bienvenido, ${currentUser ? `${currentUser.nombre} ${currentUser.apellido}` : "Usuario"}`

      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Comprobantes", link: "#" },
        { title: "Consultar Comprobantes", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
    fetchTipoDocumento()
    fetchEmpresas() // Llamar a la función para obtener las empresas
  }, [props])

  const handlePageClick = data => {
    const selectedPage = data.selected + 1
    console.log("Selected page: ", selectedPage)
    setCurrentPage(selectedPage)
    fetchFacturas(selectedPage)
  }

  const handleExportarExcel = async () => {
    setLoading(true)
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    try {
      const response = await axios.get(`${baseURL}invoice/exporta`, {
        params: {
          nitemisor: nitemisor,
          sucursal: selectedSucursal,
          prefijo: selectedPrefijo,
          fechaInicio: fechaInicio
            ? fechaInicio.toISOString().split("T")[0]
            : "",
          fechaFin: fechaFin ? fechaFin.toISOString().split("T")[0] : "",
          tipoDocumento:
            tipoDocumento !== "TODOS LOS DOCUMENTOS"
              ? tipoDocumento
              : undefined,
          tipoReceptor: tipoReceptor,
        },
        headers: {
          Authorization: `Bearer ${currentUser.access}`,
        },
      })

      const { facturas } = response.data

      if (facturas.length === 0) {
        alert("No hay datos para exportar.")
        return
      }

      exportarExcel(facturas)
    } catch (error) {
      console.error("Error al exportar a Excel:", error)
      if (error.response && error.response.status === 400) {
        alert(error.response.data.msg)
      } else {
        alert("Ocurrió un error al exportar los datos.")
      }
    } finally {
      setLoading(false)
    }
  }

  const exportarComprobantes = async () => {
    setLoading(true) // Activa el estado de carga
    try {
      const tipodocumentoe =
        tipoDocumento !== "TODOS LOS DOCUMENTOS" ? tipoDocumento : undefined

      const fechaInicioE = fechaInicio
        ? fechaInicio.toISOString().split("T")[0]
        : ""
      const fechaFinE = fechaFin ? fechaFin.toISOString().split("T")[0] : ""

      console.log("fechaInicioE", fechaInicioE)
      console.log("fechaFinE", fechaFinE)
      console.log("tipodocumentoe", tipodocumentoe)
      console.log("nitemisor", nitemisor)
      console.log("selectedPrefijo", selectedPrefijo)
      console.log("selectedSucursal", selectedSucursal)
      console.log("tipoReceptor", tipoReceptor)

      const response = await axios.get(`${baseURL}invoice/exporta`, {
        params: {
          fechaInicio: fechaInicioE,
          fechaFin: fechaFinE,
          tipoDocumento: tipodocumentoe,
          nitemisor: nitemisor,
          prefijo: selectedPrefijo,
          sucursal: selectedSucursal,
          tipoReceptor: tipoReceptor,
        },
        responseType: "blob", // Para manejar archivos
        headers: {
          "Content-Type": "application/json",
        },
      })

      // Crear una URL para descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "comprobantes.xlsx")
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error("Error al exportar comprobantes:", error)
    } finally {
      setLoading(false) // Desactiva el estado de carga al finalizar
    }
  }

  const exportarExcel = datos => {
    const datosExcel = datos.map(item => ({
      ID: item.id,
      Emisor: item.nombreemisor,
      "NIT Emisor": item.nitemisor,
      "Tipo Comprobante": item.tipocomprobante,
      Receptor: item.nombrereceptor,
      "NIT Receptor": item.nitreceptor,
      Prefijo: item.prefijo,
      Folio: item.folio,
      CUFE: item.cufe,
      Fecha: item.fecha,
      Total: item.total,
      Impuesto: item.impuesto,
      Subtotal: item.subtotal,
      "Email Receptor": item.mailreceptor,
      "Message ID": item.messageid,
      Sucursal: item.sucursal,
      "Factura Referencia": item.facref,
    }))

    const worksheet = XLSX.utils.json_to_sheet(datosExcel)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Facturas")

    XLSX.writeFile(workbook, "facturas.xlsx")
  }

  const handleSubmit = e => {
    e.preventDefault()
    fetchFacturas(1)
  }

  const getDocumentoNombre = code => {
    const doc = documentos.find(doc => doc.code === code)
    return doc ? doc.name : code
  }

  const storedUser2 = localStorage.getItem("authUser")
  const currentUser2 = storedUser2 ? JSON.parse(storedUser2) : null

  const empresaOptions = empresas.map(empresa => {
    let value, label

    if (currentUser2.role === "SuperAdmin") {
      // Para SuperAdmin
      value = empresa.identificacion
      label = empresa.nombre
    } else {
      // Para otros roles
      value = empresa.idempresa.identificacion
      label = empresa.idempresa.nombre
    }

    return { value, label }
  })

  const sucursalOptions = [
    { value: "", label: "Todos" },
    ...sucursales.map(sucursal => ({
      value: sucursal._id,
      label: sucursal.Nombretienda,
    })),
  ]

  const handleNitemisorChange = selectedOption => {
    setNitemisor(selectedOption ? selectedOption.value : "")
    setSelectedSucursal("") // Resetear la sucursal seleccionada
    fetchSucursales(selectedOption ? selectedOption.value : "")
  }

  const handleSucursalChange = selectedOption => {
    setSelectedSucursal(selectedOption ? selectedOption.label : "")
    setSelectedSucursalId(selectedOption ? selectedOption.value : "")
    setSelectedPrefijo("") // Reiniciar el prefijo seleccionado
    if (selectedOption) {
      fetchPrefijos(selectedOption.value)
    } else {
      setPrefijos([]) // Si no hay sucursal seleccionada, vaciamos los prefijos
    }
  }

  const prefijoOptions = [
    { value: "", label: "Todos" },
    ...prefijos.map(prefijo => ({
      value: prefijo.prefijo,
      label: `${prefijo.prefijo} - ${prefijo.tipodocumento}`,
    })),
  ]

  const tipoReceptorOptions = [
    { value: "Todos", label: "Todos" },
    { value: "Publico General", label: "Público General" },
    { value: "Terceros", label: "Adquiriente" },
  ]

  const customStyles = {
    control: provided => ({
      ...provided,
      zIndex: 1000,
    }),
    menu: provided => ({
      ...provided,
      zIndex: 1050,
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Facturas</CardTitle>
                <Button color="danger" onClick={() => navigate("/portal")}>
                  Salir
                </Button>
              </div>
              <p className="card-title-desc">
                Visualice y gestione las facturas emitidas.
              </p>
              <hr />

              <Form onSubmit={handleSubmit} inline>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="nitemisor">NIT Emisor</Label>
                      <Select
                        id="nitemisor"
                        value={empresaOptions.find(
                          option => option.value === nitemisor,
                        )}
                        onChange={handleNitemisorChange}
                        options={empresaOptions}
                        classNamePrefix="select2-selection"
                        placeholder="Seleccione una empresa"
                        isClearable
                        required
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <Label for="sucursales">Sucursales</Label>
                      <Select
                        id="sucursales"
                        value={sucursalOptions.find(
                          option => option.value === selectedSucursal,
                        )}
                        onChange={handleSucursalChange}
                        options={sucursalOptions}
                        classNamePrefix="select2-selection"
                        placeholder="Seleccione una sucursal"
                        isClearable
                        required
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <Label for="prefijo">Prefijo</Label>
                      <Select
                        id="prefijo"
                        value={prefijoOptions.find(
                          option => option.value === selectedPrefijo,
                        )}
                        onChange={selectedOption =>
                          setSelectedPrefijo(
                            selectedOption ? selectedOption.value : "",
                          )
                        }
                        options={prefijoOptions}
                        classNamePrefix="select2-selection"
                        placeholder="Seleccione un prefijo"
                        isClearable
                        isDisabled={prefijoOptions.length === 0}
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <Label for="tipoReceptor">Tipo de Receptor</Label>
                      <Select
                        id="tipoReceptor"
                        value={tipoReceptorOptions.find(
                          option => option.value === tipoReceptor,
                        )}
                        onChange={selectedOption =>
                          setTipoReceptor(
                            selectedOption ? selectedOption.value : "",
                          )
                        }
                        options={tipoReceptorOptions}
                        placeholder="Seleccione un tipo de receptor"
                        isClearable
                        required
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <FormGroup>
                      <Label for="fechaInicio">Fecha Inicio</Label>
                      <DatePicker
                        selected={fechaInicio}
                        onChange={date => setFechaInicio(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="fechaFin">Fecha Fin</Label>
                      <DatePicker
                        selected={fechaFin}
                        onChange={date => setFechaFin(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="tipoDocumento">Tipo Documento</Label>
                      <Input
                        type="select"
                        id="tipoDocumento"
                        value={tipoDocumento}
                        onChange={e => setTipoDocumento(e.target.value)}
                        required
                      >
                        <option value="TODOS LOS DOCUMENTOS">
                          Todos los Documentos
                        </option>
                        {documentos.map(type => (
                          <option key={type.code} value={type.code}>
                            {type.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col
                    md={1}
                    className="d-flex align-items-end justify-content-end"
                  >
                    <Button type="submit" color="primary">
                      Consultar
                    </Button>
                  </Col>
                  <Col
                    md={1}
                    className="d-flex align-items-end justify-content-end"
                  >
                    <Button
                      color="success"
                      onClick={exportarComprobantes}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Procesando...
                        </>
                      ) : (
                        "Exportar"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
              <hr />

              {loading ? (
                <p>Cargando...</p>
              ) : (
                <div>
                  <div>Total registros: {totalDocs}</div>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0 fixed-header"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead className="sticky-header">
                          <Tr>
                            <Th>Acción</Th>
                            <Th>Nombre Emisor</Th>
                            <Th>Sucursal</Th>
                            <Th>NIT Emisor</Th>
                            <Th>Tipo Comprobante</Th>
                            <Th>Nombre Receptor</Th>
                            <Th>NIT Receptor</Th>
                            <Th>Prefijo</Th>
                            <Th>Folio</Th>
                            <Th>CUFE</Th>
                            <Th>Fecha</Th>
                            <Th>Subtotal</Th>
                            <Th>Impuesto</Th>
                            <Th>Total</Th>
                            <Th>Email Receptor</Th>
                            <Th>Factura Ref</Th>
                            <Th>Folio POS</Th>
                            <Th>CheckID</Th>
                            <Th>Orden</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {facturas.map(factura => (
                            <Tr key={factura.id}>
                              <Td>
                                <div className="d-flex justify-content-around">
                                  <Button
                                    id={`btDIAN-${factura.id}`}
                                    type="button"
                                    outline
                                    color="info"
                                    className="waves-effect waves-light"
                                    onClick={() =>
                                      handleDianClick(factura.cufe)
                                    }
                                  >
                                    <i className="fas fa-building"></i>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target={`btDIAN-${factura.id}`}
                                    >
                                      DIAN
                                    </UncontrolledTooltip>
                                  </Button>
                                  <Button
                                    id={`btDetalle-${factura.id}`}
                                    type="button"
                                    outline
                                    color="primary"
                                    className="waves-effect waves-light"
                                    onClick={() =>
                                      handleDetalleClick(
                                        factura.prefijo,
                                        factura.folio,
                                        factura.nitemisor,
                                        factura.nitreceptor,
                                      )
                                    }
                                  >
                                    <i className="fas fa-eye"></i>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target={`btDetalle-${factura.id}`}
                                    >
                                      Detalle
                                    </UncontrolledTooltip>
                                  </Button>
                                  <Button
                                    id={`btPDF-${factura.id}`}
                                    type="button"
                                    outline
                                    color="info"
                                    className="waves-effect waves-light"
                                    onClick={() => handlePDFClick(factura.cufe)}
                                    disabled={loadingPDFs[factura.cufe]}
                                  >
                                    {loadingPDFs[factura.cufe] ? (
                                      <>
                                        <Spinner size="sm" color="primary" />
                                        {downloadProgresses[factura.cufe] !==
                                        undefined
                                          ? ` ${downloadProgresses[factura.cufe]}%`
                                          : " Descargando..."}
                                      </>
                                    ) : (
                                      <i className="fas fa-file-pdf"></i>
                                    )}
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target={`btPDF-${factura.id}`}
                                    >
                                      PDF
                                    </UncontrolledTooltip>
                                  </Button>
                                  {/* Botón para reenviar correo */}
                                  {/* El botón de reenvío solo se muestra cuando nitemisor es diferente a "222222222222" */}
                                  {factura.nitreceptor !== "222222222222" && (
                                    <Button
                                      id={`btReenvio-${factura.id}`}
                                      type="button"
                                      outline
                                      color="warning"
                                      className="waves-effect waves-light"
                                      onClick={() =>
                                        handleResendEmail(factura.cufe)
                                      }
                                    >
                                      <i className="fas fa-envelope"></i>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target={`btReenvio-${factura.id}`}
                                      >
                                        Reenviar correo
                                      </UncontrolledTooltip>
                                    </Button>
                                  )}
                                </div>
                              </Td>
                              <Td>{factura.nombreemisor}</Td>
                              <Td>{factura.sucursal}</Td>
                              <Td>{factura.nitemisor}</Td>
                              <Td>
                                {getDocumentoNombre(factura.tipocomprobante)}
                              </Td>
                              <Td>{factura.nombrereceptor}</Td>
                              <Td>{factura.nitreceptor}</Td>
                              <Td>{factura.prefijo}</Td>
                              <Td>{factura.folio}</Td>
                              <Td>{factura.cufe}</Td>
                              <Td>{factura.fecha}</Td>
                              <Td>{formatCurrency(factura.subtotal)}</Td>
                              <Td>{formatCurrency(factura.impuesto)}</Td>
                              <Td>{formatCurrency(factura.total)}</Td>
                              <Td>{factura.mailreceptor}</Td>
                              <Td>{factura.facref}</Td>
                              <Td>{factura.foliopos}</Td>
                              <Td>{factura.checkid}</Td>
                              <Td>{factura.identificadororden}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div>Total registros: {totalDocs}</div>
                    <ReactPaginate
                      previousLabel={"← Anterior"}
                      nextLabel={"Siguiente →"}
                      pageCount={totalPages}
                      forcePage={currentPage}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                    />
                  </div>
                </div>
              )}
              <Modal
                isOpen={modal}
                toggle={toggleModal}
                size="lg"
                style={{ maxWidth: "80%" }}
              >
                <ModalHeader toggle={toggleModal}>DIAN Document</ModalHeader>
                <ModalBody>
                  <iframe
                    src={modalContent}
                    style={{ width: "100%", height: "600px" }}
                    title="DIAN Document"
                  ></iframe>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggleModal}>
                    Cerrar
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal
                isOpen={detalleModal}
                toggle={toggleDetalleModal}
                size="lg"
              >
                <ModalHeader toggle={toggleDetalleModal}>
                  Detalle del Comprobante
                </ModalHeader>
                <ModalBody>
                  {detalleContent && (
                    <div>
                      <p>
                        <strong>Emisor:</strong>
                      </p>
                      <ul>
                        <li>
                          <strong>Nombre:</strong>{" "}
                          {detalleContent.emisor.nombre} (
                          {detalleContent.emisor.identificacion})
                        </li>
                      </ul>

                      <p>
                        <strong>Adquiriente:</strong>
                      </p>
                      <ul>
                        <li>
                          <strong>Nombre:</strong>{" "}
                          {detalleContent.adquiriente.nombre} (
                          {detalleContent.adquiriente.identificacion})
                        </li>
                        <li>
                          <strong>Dirección:</strong>{" "}
                          {detalleContent.adquiriente.direccion}
                        </li>
                        <li>
                          <strong>Correo:</strong>{" "}
                          {detalleContent.adquiriente.correo}
                        </li>
                        <li>
                          <strong>Obligaciones:</strong>{" "}
                          {detalleContent.adquiriente.obligaciones}
                        </li>
                        <li>
                          <strong>Tributo:</strong>{" "}
                          {detalleContent.adquiriente.tributo}
                        </li>
                        <li>
                          <strong>Régimen:</strong>{" "}
                          {detalleContent.adquiriente.regimen}
                        </li>
                        <li>
                          <strong>Pago:</strong> {detalleContent.pago.metodo} -{" "}
                          {detalleContent.pago.medio}
                        </li>
                      </ul>
                      <p>
                        <strong>Importes:</strong>
                      </p>
                      <ul>
                        <li>
                          <strong>Total:</strong>{" "}
                          {formatCurrency(
                            detalleContent.importes.total["$numberDecimal"],
                          )}
                        </li>
                        <li>
                          <strong>Subtotal:</strong>{" "}
                          {formatCurrency(
                            detalleContent.importes.subtotal["$numberDecimal"],
                          )}
                        </li>
                        <li>
                          <strong>Impuesto:</strong>{" "}
                          {formatCurrency(
                            detalleContent.importes.impuesto["$numberDecimal"],
                          )}
                        </li>
                        <li>
                          <strong>IVA:</strong>{" "}
                          {formatCurrency(
                            detalleContent.importes.iva["$numberDecimal"],
                          )}
                        </li>
                        <li>
                          <strong>INC:</strong>{" "}
                          {formatCurrency(
                            detalleContent.importes.inc["$numberDecimal"],
                          )}
                        </li>
                        <li>
                          <strong>Otros Impuestos:</strong>{" "}
                          {formatCurrency(
                            detalleContent.importes.otrosimpuestos[
                              "$numberDecimal"
                            ],
                          )}
                        </li>
                        <li>
                          <strong>Total Cargos:</strong>{" "}
                          {formatCurrency(
                            detalleContent.importes.totalcargos[
                              "$numberDecimal"
                            ],
                          )}
                        </li>
                        <li>
                          <strong>Total Descuentos:</strong>{" "}
                          {formatCurrency(
                            detalleContent.importes.totaldescuentos[
                              "$numberDecimal"
                            ],
                          )}
                        </li>
                        <li>
                          <strong>Total Anticipo:</strong>{" "}
                          {formatCurrency(
                            detalleContent.importes.totalAnticipo[
                              "$numberDecimal"
                            ],
                          )}
                        </li>
                      </ul>
                      <p>
                        <strong>Archivos:</strong>
                      </p>
                      <ul>
                        <li>
                          <a
                            href={detalleContent.archivos.invoice}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            XML Invoice
                          </a>
                        </li>
                        <li>
                          <a
                            href={detalleContent.archivos.resdian}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Respuesta DIAN
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggleDetalleModal}>
                    Cerrar
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    authData: state.Login.user,
  }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(Facturas)
