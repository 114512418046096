import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Table,
} from "reactstrap"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"

// Íconos de react-icons
import { FaExclamationTriangle, FaCheck, FaTimesCircle } from "react-icons/fa"

const baseURL = process.env.REACT_APP_API_URL

const GenerarExcel = props => {
  const navigate = useNavigate()

  // -- ESTADOS PRINCIPALES --
  const [empresas, setEmpresas] = useState([])
  const [documentos, setDocumentos] = useState([])
  const [tipoReporte, setTipoReporte] = useState("")
  const [nitemisor, setNitemisor] = useState("")
  const [fechaInicio, setFechaInicio] = useState(null)
  const [fechaFin, setFechaFin] = useState(null)
  const [tipoDocumento, setTipoDocumento] = useState("TODOS LOS DOCUMENTOS")

  const [sucursales, setSucursales] = useState([])
  const [selectedSucursal, setSelectedSucursal] = useState("")
  const [selectedSucursalId, setSelectedSucursalId] = useState("")

  const [prefijos, setPrefijos] = useState([])
  const [selectedPrefijo, setSelectedPrefijo] = useState("")

  const [loading, setLoading] = useState(false)
  const [generating, setGenerating] = useState(false)

  const [formato, setFormato] = useState("pdf")

  // Lista de solicitudes de reporte
  const [reportRequests, setReportRequests] = useState([])

  // Manejo del estado al refrescar
  const [refreshing, setRefreshing] = useState(false)

  // -- DATOS DE USUARIO --
  const storedUser = localStorage.getItem("authUser")
  const currentUser = storedUser ? JSON.parse(storedUser) : null

  // useEffect inicial
  useEffect(() => {
    if (currentUser) {
      const userGreeting = `Bienvenido, ${
        currentUser
          ? `${currentUser.nombre} ${currentUser.apellido}`
          : "Usuario"
      }`
      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Generar Reportes", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
    fetchTipoDocumento()
    fetchEmpresas()
    fetchReportRequests()
  }, [])

  // ==================================
  // Función para recargar la tabla
  // ==================================
  const handleRefrescar = async () => {
    setRefreshing(true)
    await fetchReportRequests()
    setRefreshing(false)
  }

  // ==================================
  // GET /reportes
  // ==================================
  const fetchReportRequests = async () => {
    try {
      if (!currentUser || !currentUser._id) return
      const res = await axios.get(`${baseURL}reportes`, {
        params: { userId: currentUser._id },
        headers: {
          Authorization: `Bearer ${currentUser.access}`,
        },
      })
      if (res.data && res.data.success) {
        setReportRequests(res.data.data)
      }
    } catch (err) {
      console.error("Error al obtener reportes:", err)
    }
  }

  // ========================================
  // Funciones para traer datos de catálogos
  // ========================================
  const fetchTipoDocumento = async () => {
    try {
      const response = await axios.get("/listas/TipoDocumento-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const types = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          types.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }
      setDocumentos(types)
    } catch (error) {
      console.error("Error fetching document types:", error)
    }
  }

  const fetchEmpresas = async () => {
    if (!currentUser) return

    if (currentUser.role === "SuperAdmin") {
      if (!currentUser.IdEmpresa || !currentUser.access) return
      try {
        const response = await axios.get(
          `${baseURL}empresas/obtener?id=${currentUser.IdEmpresa}`,
          {
            headers: { Authorization: `Bearer ${currentUser.access}` },
          },
        )
        setEmpresas(response.data)
      } catch (error) {
        console.error("Error fetching empresas data:", error)
      }
    } else {
      if (!currentUser.empresas) return
      try {
        setEmpresas(currentUser.empresas)
      } catch (error) {
        console.error("Error setting empresas data:", error)
      }
    }
  }

  const fetchSucursales = async nit => {
    if (!currentUser || !currentUser.access) return
    setLoading(true)
    try {
      const response = await axios.get(
        `${baseURL}empresas/sucursales?id=${nit}`,
        {
          headers: { Authorization: `Bearer ${currentUser.access}` },
        },
      )
      setSucursales(response.data.sucursales || [])
    } catch (error) {
      console.error("Error fetching sucursales data:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchPrefijos = async sucursalId => {
    if (!currentUser || !currentUser.access) return
    setLoading(true)
    try {
      const response = await axios.get(`${baseURL}resoluciones/prefijos`, {
        params: { sucursal: sucursalId },
        headers: { Authorization: `Bearer ${currentUser.access}` },
      })
      setPrefijos(response.data.prefijos || [])
    } catch (error) {
      console.error("Error fetching prefijos data:", error)
      setPrefijos([])
    } finally {
      setLoading(false)
    }
  }

  // Manejo de selects (empresa, sucursal, prefijo, etc.)
  const empresaOptions = empresas.map(empresa => {
    let value, label
    if (currentUser && currentUser.role === "SuperAdmin") {
      value = empresa.identificacion
      label = empresa.nombre
    } else {
      value = empresa.idempresa.identificacion
      label = empresa.idempresa.nombre
    }
    return { value, label }
  })

  const handleNitemisorChange = selectedOption => {
    setNitemisor(selectedOption ? selectedOption.value : "")
    setSelectedSucursal("")
    setSelectedSucursalId("")
    setSelectedPrefijo("")
    if (selectedOption) {
      fetchSucursales(selectedOption.value)
    } else {
      setSucursales([])
      setPrefijos([])
    }
  }

  const sucursalOptions = [
    { value: "", label: "Todos" },
    ...sucursales.map(s => ({ value: s._id, label: s.Nombretienda })),
  ]

  const handleSucursalChange = selectedOption => {
    setSelectedSucursal(selectedOption ? selectedOption.label : "")
    setSelectedSucursalId(selectedOption ? selectedOption.value : "")
    setSelectedPrefijo("")
    if (selectedOption && selectedOption.value) {
      fetchPrefijos(selectedOption.value)
    } else {
      setPrefijos([])
    }
  }

  const prefijoOptions = [
    { value: "", label: "Todos" },
    ...prefijos.map(p => ({
      value: p.prefijo,
      label: `${p.prefijo} - ${p.tipodocumento}`,
    })),
  ]

  // ==============================================
  // Handler principal para crear la solicitud
  // ==============================================
  const isWithinOneMonth = (start, end) => {
    if (!start || !end) return true
    const diffInMs = end.getTime() - start.getTime()
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24)
    return diffInDays <= 31
  }

  const handleSubmit = async e => {
    e.preventDefault()

    // Validar rango de 1 mes (si aplica a "facturasGeneradas")
    if (tipoReporte === "informeVentasDiarias") {
      if (!isWithinOneMonth(fechaInicio, fechaFin)) {
        alert("El rango de fechas no puede superar 1 mes.")
        return
      }
    }

    if (!currentUser || !currentUser._id) {
      alert("No se encontró el usuario para asociar el reporte.")
      return
    }

    setGenerating(true)
    try {
      let paramPrefijo = selectedPrefijo
      if (selectedPrefijo === "") {
        // "Todos": concatenar todos los prefijos
        paramPrefijo = prefijos.map(p => p.prefijo).join(",")
      }

      const requestBody = {
        userId: currentUser._id,
        typeOfReport: tipoReporte,
        fileType: formato,
        filters: {
          startDate: fechaInicio,
          endDate: fechaFin,
          nitEmisor: nitemisor,
          sucursal: selectedSucursalId,
          prefijo: paramPrefijo,
          nitReceptor: "",
          tipoDocumento:
            tipoReporte === "informeVentasDiarias" ? tipoDocumento : undefined,
        },
      }

      const res = await axios.post(`${baseURL}reportes`, requestBody, {
        headers: {
          Authorization: `Bearer ${currentUser.access}`,
        },
      })

      if (res.data && res.data.success) {
        // Si quieres quitar el alert, simplemente coméntalo o elimínalo
        // alert("Solicitud de reporte creada correctamente.")
        fetchReportRequests()
      } else {
        console.error("Error en la respuesta de crear reporte:", res.data)
      }
    } catch (error) {
      console.error("Error al crear la solicitud de reporte:", error)
    } finally {
      setGenerating(false)
    }
  }

  // =====================================
  // Render del icono/spinner según status
  // =====================================
  const renderStatusIcon = status => {
    switch (status) {
      case "pendiente":
        // Exclamación naranja
        return <FaExclamationTriangle color="orange" />
      case "en_progreso":
        // Spinner
        return <Spinner size="sm" color="info" />
      case "completado":
        // Check verde
        return <FaCheck color="green" />
      case "fallo":
        // X roja
        return <FaTimesCircle color="red" />
      default:
        return null
    }
  }

  // ======================
  // Render del componente
  // ======================
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <CardTitle className="h4">Generar Reporte</CardTitle>
              <Button color="danger" onClick={() => navigate("/portal")}>
                Salir
              </Button>
            </div>
            <p className="card-title-desc">
              Seleccione filtros y cree una solicitud de reporte.
            </p>
            <hr />

            {/* Spinner al crear la solicitud de reporte */}
            {generating && (
              <div
                className="mb-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Spinner color="primary" />
                <span style={{ marginLeft: "10px" }}>
                  Creando solicitud de reporte...
                </span>
              </div>
            )}

            {/* FORMULARIO DE FILTROS */}
            <Form onSubmit={handleSubmit} inline>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label for="tipoReporte">Tipo de Reporte</Label>
                    <Input
                      type="select"
                      id="tipoReporte"
                      value={tipoReporte}
                      onChange={e => setTipoReporte(e.target.value)}
                      required
                    >
                      <option value="">Seleccione un tipo</option>
                      <option value="informeVentasDiarias">
                        INFORME DE VENTAS DIARIAS
                      </option>
                      <option value="foliosFaltantes">FOLIOS FALTANTES</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <Label for="formato">Formato</Label>
                    <Input
                      type="select"
                      id="formato"
                      value={formato}
                      onChange={e => setFormato(e.target.value)}
                      required
                    >
                      <option value="pdf">PDF</option>
                      <option value="excel">Excel</option>
                      <option value="csv">CSV</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <Label for="nitemisor">NIT Emisor</Label>
                    <Select
                      id="nitemisor"
                      value={empresaOptions.find(
                        opt => opt.value === nitemisor,
                      )}
                      onChange={handleNitemisorChange}
                      options={empresaOptions}
                      classNamePrefix="select2-selection"
                      placeholder="Seleccione una empresa"
                      isClearable
                    />
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <Label for="sucursales">Sucursales</Label>
                    <Select
                      id="sucursales"
                      value={sucursalOptions.find(
                        opt => opt.value === selectedSucursalId,
                      )}
                      onChange={handleSucursalChange}
                      options={sucursalOptions}
                      classNamePrefix="select2-selection"
                      placeholder="Seleccione una sucursal"
                      isClearable
                    />
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <Label for="prefijo">Prefijo</Label>
                    <Select
                      id="prefijo"
                      value={prefijoOptions.find(
                        opt => opt.value === selectedPrefijo,
                      )}
                      onChange={opt => setSelectedPrefijo(opt ? opt.value : "")}
                      options={prefijoOptions}
                      classNamePrefix="select2-selection"
                      placeholder="Seleccione un prefijo"
                      isClearable
                      isDisabled={prefijoOptions.length === 0}
                    />
                  </FormGroup>
                </Col>

                {tipoReporte === "informeVentasDiarias" && (
                  <>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="fechaInicio">Fecha Inicio</Label>
                        <DatePicker
                          selected={fechaInicio}
                          onChange={date => setFechaInicio(date)}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="fechaFin">Fecha Fin</Label>
                        <DatePicker
                          selected={fechaFin}
                          onChange={date => setFechaFin(date)}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="tipoDocumento">Tipo Documento</Label>
                        <Input
                          type="select"
                          id="tipoDocumento"
                          value={tipoDocumento}
                          onChange={e => setTipoDocumento(e.target.value)}
                          required
                        >
                          <option value="TODOS LOS DOCUMENTOS">
                            Todos los Documentos
                          </option>
                          {documentos.map(type => (
                            <option key={type.code} value={type.code}>
                              {type.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </>
                )}

                <Col
                  md={2}
                  className="d-flex align-items-end justify-content-end"
                >
                  <Button type="submit" color="primary" disabled={generating}>
                    {generating ? "Procesando..." : "Generar"}
                  </Button>
                </Col>
              </Row>
            </Form>

            <hr />
            {loading && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Spinner color="secondary" />
                <span style={{ marginLeft: "10px" }}>Cargando...</span>
              </div>
            )}

            {/* ============================================================ */}
            {/* TABLA CON LAS SOLICITUDES DE REPORTE DEL USUARIO             */}
            {/* ============================================================ */}
            <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
              <h5 className="mb-0">Mis Solicitudes de Reporte</h5>
              {/* Botón para refrescar la lista de reportes */}
              <Button
                color={refreshing ? "info" : "secondary"}
                onClick={handleRefrescar}
              >
                {refreshing ? "Actualizando..." : "Refrescar"}
              </Button>
            </div>

            <Table bordered striped>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Tipo de Reporte</th>
                  <th>Formato</th>
                  <th>Estado</th>
                  <th>Fecha Solicitud</th>
                  <th>Descarga</th>
                </tr>
              </thead>
              <tbody>
                {reportRequests.length === 0 ? (
                  <tr>
                    <td colSpan="6">
                      No hay solicitudes de reporte registradas.
                    </td>
                  </tr>
                ) : (
                  reportRequests.map(req => (
                    <tr key={req._id}>
                      <td>{req._id}</td>
                      <td>{req.typeOfReport}</td>
                      <td>{req.fileType}</td>
                      <td>{renderStatusIcon(req.status)}</td>
                      <td>
                        {new Date(req.createdAt).toLocaleString("es-CO", {
                          timeZone: "America/Bogota",
                        })}
                      </td>
                      <td>
                        {req.status === "completado" && req.fileUrl ? (
                          <a
                            href={req.fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Descargar
                          </a>
                        ) : req.status === "fallo" ? (
                          // Si quieres además del ícono, mostrar mensaje de error
                          <span style={{ color: "red" }}>
                            Error: {req.errorMessage || "desconocido"}
                          </span>
                        ) : (
                          // Si no está fallido ni completado, solo muestra un texto
                          "Pendiente"
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

const mapStateToProps = state => {
  return {
    authData: state.Login.user,
  }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(GenerarExcel)
