import React, { useEffect, useState, useCallback } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Spinner,
  Input, // Se agregó Input para el campo de fecha
} from "reactstrap"
import { connect } from "react-redux"
import axios from "axios"
import classnames from "classnames"
import { useParams, useNavigate } from "react-router-dom"
import ReactPaginate from "react-paginate"
import Select from "react-select"

// Importar la acción para copiar los elementos de breadcrumb del estado local al estado de Redux
import { setBreadcrumbItems } from "../../store/actions"
import "./Empresas.scss"
const baseURL = process.env.REACT_APP_API_URL

const Resoluciones = props => {
  document.title = "Resoluciones | LS-DA - Softlogy"

  const { nit, id } = useParams()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState("1")
  const [resolucionesAsignadas, setResolucionesAsignadas] = useState([])
  const [resolucionesSinAsignar, setResolucionesSinAsignar] = useState([])
  const [sucursales, setSucursales] = useState([]) // Estado para almacenar las sucursales
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage] = useState(10)
  const [modal, setModal] = useState(false)
  const [selectedResolution, setSelectedResolution] = useState(null)
  const [selectedSucursal, setSelectedSucursal] = useState("")
  // Estado para la fecha de inicio. Se inicializa con la fecha actual en formato "yyyy-mm-dd"
  const [fechaInicio, setFechaInicio] = useState(
    new Date().toISOString().substr(0, 10),
  )
  const [updatingFolio, setUpdatingFolio] = useState(false)

  const fetchResoluciones = useCallback(async () => {
    if (!nit) {
      console.log("No nit provided, skipping fetch.")
      return
    }
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    setLoading(true)
    try {
      const [responseAsignadas, responseSinAsignar, responseSucursales] =
        await Promise.all([
          axios.get(`${baseURL}resoluciones/obtener?nit=${nit}`),
          axios.get(
            `${baseURL}resoluciones/obtener?nit=${nit}&sinSucursal=true`,
          ),
          axios.get(`${baseURL}empresas/sucursales?id=${nit}`, {
            headers: {
              Authorization: `Bearer ${currentUser.access}`,
            },
          }),
        ])

      const asignadasData = Array.isArray(responseAsignadas.data)
        ? responseAsignadas.data
        : []
      const sinAsignarData = Array.isArray(responseSinAsignar.data)
        ? responseSinAsignar.data
        : []

      setResolucionesAsignadas(asignadasData)
      setResolucionesSinAsignar(sinAsignarData)
      setSucursales(responseSucursales.data.sucursales || [])
    } catch (error) {
      console.error("Error fetching resoluciones data:", error)
    } finally {
      setLoading(false)
    }
  }, [nit, id])

  useEffect(() => {
    fetchResoluciones()
  }, [fetchResoluciones])

  const toggleModal = resolution => {
    setSelectedResolution(resolution)
    setModal(!modal)
  }

  const handleAssign = async () => {
    if (selectedResolution && selectedSucursal) {
      try {
        const sucursalSeleccionada = sucursales.find(
          sucursal => sucursal._id === selectedSucursal,
        )
        if (sucursalSeleccionada) {
          const { brandid, storeid } = sucursalSeleccionada
          const { prefijo } = selectedResolution

          console.log("brandid:", brandid)
          console.log("storeid:", storeid)
          console.log("prefijo:", prefijo)
          console.log("fechaInicio:", fechaInicio) // Se muestra la fecha seleccionada

          // En caso de que el backend requiera enviar la fecha de inicio, se agrega aquí
          const response = await axios.post(`${baseURL}resoluciones/sucursal`, {
            nit,
            brandid,
            storeid,
            prefijo,
            fechaInicio,
          })

          if (response.status === 200) {
            alert("Resolución asignada correctamente")
            setModal(false)
            fetchResoluciones() // Refrescar datos
          }
        } else {
          console.error("Sucursal no encontrada")
        }
      } catch (error) {
        console.error("Error asignando resolución:", error)
      }
    }
  }

  const handleActualizarFolio = async () => {
    setUpdatingFolio(true)
    try {
      const response = await axios.post(`${baseURL}resoluciones/ultimofolio`, {
        nit,
      })
      if (response.status === 200) {
        alert("Prefijos actualizados correctamente")
        fetchResoluciones()
      }
    } catch (error) {
      console.error("Error actualizando folios de prefijos:", error)
      alert("Error actualizando folios de prefijos.")
    } finally {
      setUpdatingFolio(false)
    }
  }

  const dataTableOptions = {
    lengthMenu: "Mostrar _MENU_ registros",
    zeroRecords: "No se encontraron registros coincidentes",
    info: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
    infoFiltered: "(filtrado de un total de _MAX_ registros)",
    search: "Buscar:",
    paginate: {
      first: "Primero",
      last: "Último",
      next: "Siguiente",
      previous: "Anterior",
    },
    aria: {
      sortAscending: ": activar para ordenar la columna de manera ascendente",
      sortDescending: ": activar para ordenar la columna de manera descendente",
    },
  }

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handlePageClick = data => {
    setCurrentPage(data.selected)
  }

  const renderTable = (
    resoluciones = [],
    title,
    isSinAsignar = false,
    tipoDocumento,
  ) => {
    if (!Array.isArray(resoluciones) || resoluciones.length === 0) {
      return <div>No hay resoluciones para mostrar.</div>
    }

    const rows = resoluciones.flatMap(resolucion => {
      const prefijosFiltrados = resolucion.prefijos.filter(
        prefijo => prefijo.tipodocumento === tipoDocumento,
      )

      return prefijosFiltrados.map(prefijo => {
        return {
          ultimofolio: prefijo.ulitmoFolio,
          ultimafecha: prefijo.fechaUltimoFolio,
          resolucion: resolucion.numeroResolucion,
          fechaInicial: new Date(resolucion.fechaInicio).toLocaleDateString(),
          fechaVencimiento: new Date(
            resolucion.fechaFinal,
          ).toLocaleDateString(),
          prefijo: prefijo.prefijo,
          inicial: prefijo.folioInicial,
          final: prefijo.folioFinal,
          claveTecnica: prefijo.claveTecnica,
          tipoResolucion: prefijo.tipodocumento,
          sucursal: prefijo.sucursal ? (
            prefijo.sucursal.Nombretienda
          ) : isSinAsignar ? (
            <Button
              className="btn btn-success"
              onClick={() => toggleModal({ resolucion, prefijo })}
            >
              Asignar
            </Button>
          ) : (
            "Sin Sucursal"
          ),
          id: prefijo._id,
        }
      })
    })

    if (rows.length === 0) {
      return <div>No hay resoluciones para mostrar.</div>
    }

    const offset = currentPage * itemsPerPage
    const currentPageData = rows.slice(offset, offset + itemsPerPage)

    return (
      <div>
        <MDBDataTable
          responsive
          bordered
          striped
          hover
          data={{
            columns: [
              {
                label: "Ultimo Folio",
                field: "ultimofolio",
                sort: "asc",
                width: 150,
              },
              {
                label: "Ultimo fecha",
                field: "ultimafecha",
                sort: "asc",
                width: 150,
              },
              {
                label: "Resolución",
                field: "resolucion",
                sort: "asc",
                width: 150,
              },
              {
                label: "Fecha Inicial",
                field: "fechaInicial",
                sort: "asc",
                width: 150,
              },
              {
                label: "Fecha Vencimiento",
                field: "fechaVencimiento",
                sort: "asc",
                width: 150,
              },
              { label: "Prefijo", field: "prefijo", sort: "asc", width: 100 },
              { label: "Inicial", field: "inicial", sort: "asc", width: 100 },
              { label: "Final", field: "final", sort: "asc", width: 100 },
              {
                label: "Clave Técnica",
                field: "claveTecnica",
                sort: "asc",
                width: 250,
              },
              {
                label: "Tipo Resolución",
                field: "tipoResolucion",
                sort: "asc",
                width: 150,
              },
              {
                label: isSinAsignar ? "Acción" : "Sucursal",
                field: "sucursal",
                sort: "asc",
                width: 250,
              },
            ],
            rows: currentPageData,
          }}
          entriesLabel="Mostrar _MENU_ registros"
          searchLabel="Buscar:"
          paginationLabel={["Anterior", "Siguiente"]}
          infoLabel={[
            "Mostrando registros del",
            "al",
            "de un total de",
            "registros",
          ]}
          language={dataTableOptions}
          paging={false}
          displayEntries={false}
        />
        <ReactPaginate
          previousLabel={"← Anterior"}
          nextLabel={"Siguiente →"}
          pageCount={Math.ceil(rows.length / itemsPerPage)}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
        />
      </div>
    )
  }

  const optionGroup = sucursales.map(sucursal => ({
    value: sucursal._id,
    label: sucursal.Nombretienda,
  }))

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Resoluciones</CardTitle>
                <Button
                  color="primary"
                  className="me-2"
                  onClick={handleActualizarFolio}
                  disabled={updatingFolio}
                >
                  {updatingFolio ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    "Actualizar Ultimos Folios"
                  )}
                </Button>
                <Button
                  color="danger"
                  onClick={() => navigate("/portal/consultar-empresas")}
                >
                  Salir
                </Button>
              </div>
              <p className="card-title-desc">
                Visualice y gestione las resoluciones asignadas y sin asignar a
                las sucursales.
              </p>

              {loading ? (
                <p>Cargando...</p>
              ) : (
                <div>
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => toggleTab("1")}
                      >
                        Asignadas
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => toggleTab("2")}
                      >
                        Sin Asignar
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col md="12">
                          <h5>Factura Electrónica</h5>
                          {renderTable(
                            resolucionesAsignadas,
                            "Factura Electrónica",
                            false,
                            "Factura Electronica",
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <h5>Resolución de Contingencia</h5>
                          {renderTable(
                            resolucionesAsignadas,
                            "Resolución de Contingencia",
                            false,
                            "Resolucion de Contingencia",
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col md="12">
                          <h5>Factura Electrónica</h5>
                          {renderTable(
                            resolucionesSinAsignar,
                            "Factura Electrónica",
                            true,
                            "Factura Electronica",
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <h5>Resolución de Contingencia</h5>
                          {renderTable(
                            resolucionesSinAsignar,
                            "Resolución de Contingencia",
                            true,
                            "Resolucion de Contingencia",
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={() => toggleModal(null)}>
        <ModalHeader toggle={() => toggleModal(null)}>
          Asignar Sucursal
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="sucursal">Seleccione una Sucursal</Label>
            <Select
              value={optionGroup.find(
                option => option.value === selectedSucursal,
              )}
              onChange={option => setSelectedSucursal(option.value)}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
          {/* Nuevo campo: Fecha de inicio con input type "date" que muestra un calendario */}
          <FormGroup>
            <Label for="fechaInicio">Fecha de inicio</Label>
            <Input
              type="date"
              id="fechaInicio"
              value={fechaInicio}
              onChange={e => setFechaInicio(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAssign}>
            Asignar
          </Button>{" "}
          <Button color="secondary" onClick={() => toggleModal(null)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state.Login.user,
  }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(Resoluciones)
