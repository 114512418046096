import React, { useEffect, useState, useCallback } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { Row, Col, Card, CardBody, CardTitle, Table } from "reactstrap"
import axios from "axios"

const baseURL = process.env.REACT_APP_API_URL

const Dashboard = ({ setBreadcrumbItems, user }) => {
  // Configuración de la pestaña y saludo del usuario
  document.title = "LS-DA / Softlogy"
  const userGreeting = `Bienvenido, ${
    user ? `${user.nombre} ${user.apellido}` : "Usuario"
  }`

  // Estados para empresas, carga y clientes por empresa
  const [empresas, setEmpresas] = useState([])
  const [loading, setLoading] = useState(true)
  const [clientesPorEmpresa, setClientesPorEmpresa] = useState([])

  // Configuración del breadcrumb
  useEffect(() => {
    if (user) {
      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Dashboard", link: "#" },
      ]
      setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
  }, [setBreadcrumbItems, user, userGreeting])

  // Formateador de moneda para COP
  const formatoCOP = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 2,
  })

  // Función para obtener las empresas según el rol del usuario
  const fetchEmpresas = useCallback(async currentUser => {
    if (!currentUser) {
      console.log("User data not fully available yet, skipping fetch.")
      return
    }

    console.log("Current user:", currentUser)

    if (currentUser.role === "SuperAdmin") {
      // Para SuperAdmin se obtiene la empresa mediante la API
      if (!currentUser.IdEmpresa || !currentUser.access) {
        console.log("User data not fully available yet, skipping fetch.")
        return
      }
      try {
        const response = await axios.get(
          `${baseURL}empresas/obtener?id=${currentUser.IdEmpresa}`,
          {
            headers: {
              Authorization: `Bearer ${currentUser.access}`,
            },
          },
        )
        console.log("Empresas data fetched:", response.data)
        // Si la respuesta no es un arreglo, lo convertimos en uno.
        const empresasData = Array.isArray(response.data)
          ? response.data
          : [response.data]
        setEmpresas(empresasData)
      } catch (error) {
        console.error("Error fetching empresas data:", error)
      } finally {
        setLoading(false)
      }
    } else {
      // Para otros roles, las empresas vienen en currentUser.empresas
      if (!currentUser.empresas) {
        console.log("User data not fully available yet, skipping fetch.")
        return
      }
      try {
        console.log("Empresas data fetched:", currentUser.empresas)
        setEmpresas(currentUser.empresas)
      } catch (error) {
        console.error("Error setting empresas data:", error)
      } finally {
        setLoading(false)
      }
    }
  }, [])

  // Invocar fetchEmpresas cuando el usuario esté disponible
  useEffect(() => {
    if (user) {
      fetchEmpresas(user)
    }
  }, [user, fetchEmpresas])

  // Por cada empresa, se hace una llamada a la API para obtener el Top 10 de clientes
  useEffect(() => {
    if (empresas && empresas.length > 0) {
      const fetchData = async () => {
        const requests = empresas.map(async empresa => {
          // Si existe "idempresa", usamos sus propiedades;
          // de lo contrario, asumimos que la información ya está a nivel raíz.
          const nit = empresa.idempresa
            ? empresa.idempresa.identificacion
            : empresa.identificacion || empresa.nit
          const nombreEmpresa = empresa.idempresa
            ? empresa.idempresa.nombre
            : empresa.nombre
          try {
            const response = await fetch(
              `${baseURL}reportes/top10?nitEmisor=${nit}`,
            )
            if (!response.ok) {
              throw new Error(
                "Error en la respuesta de la API para el nit " + nit,
              )
            }
            const data = await response.json()
            console.log("Respuesta de la API para nit", nit, ":", data)
            if (data.topClientes && Array.isArray(data.topClientes)) {
              const topClientes = data.topClientes.map(item => {
                const importeValor = parseFloat(
                  item.totalImporte?.$numberDecimal || item.totalImporte,
                )
                return {
                  nombre: item.nombreCliente,
                  importe: isNaN(importeValor) ? 0 : importeValor,
                }
              })
              const totalClientes = topClientes.reduce(
                (acc, cliente) => acc + cliente.importe,
                0,
              )
              return { nit, nombreEmpresa, topClientes, totalClientes }
            } else {
              console.error(
                "La propiedad 'topClientes' no es un array para el nit " + nit,
              )
              return { nit, nombreEmpresa, topClientes: [], totalClientes: 0 }
            }
          } catch (error) {
            console.error("Error al consumir la API para el nit " + nit, error)
            return { nit, nombreEmpresa, topClientes: [], totalClientes: 0 }
          }
        })
        const results = await Promise.all(requests)
        setClientesPorEmpresa(results)
      }
      fetchData()
    }
  }, [empresas])

  return (
    <React.Fragment>
      <Row>
        {clientesPorEmpresa.map((empresaData, idx) => (
          <Col xl="6" key={idx}>
            <Card className="mb-4">
              <CardBody>
                {/* Se muestra el nombre de la empresa en el título de la tabla */}
                <CardTitle tag="h5">
                  Top 10 Clientes - {empresaData.nombreEmpresa}
                </CardTitle>
                <Table className="table-centered mb-0" responsive>
                  <thead>
                    <tr>
                      <th>Cliente</th>
                      <th className="text-end">Importe</th>
                    </tr>
                  </thead>
                  <tbody>
                    {empresaData.topClientes.map((cliente, index) => (
                      <tr key={index}>
                        <td>{cliente.nombre}</td>
                        <td className="text-end">
                          {formatoCOP.format(cliente.importe)}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="fw-bold">TOTAL</td>
                      <td className="text-end fw-bold">
                        {formatoCOP.format(empresaData.totalClientes)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  user: state.Login.user,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(Dashboard)
